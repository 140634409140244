.checkbox-dropdown .dropdown-toggle::after {
  content: none;
}

.filter-icon-number {
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%); 
  font-size: 0.7em;
}