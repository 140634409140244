.table-styling {
  border-collapse: separate;
  border-spacing: 0;
  margin: 25px 0;
  font-size: 0.9em;
  width: 100%;
}

.table-styling thead tr {
  color: grey;
  text-align: left;
}

.table-styling th {
  padding: 16px 14px 12px 14px;
}

.table-styling td {
  padding: 13px 15px;
}

.table-styling tbody tr td {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.table-styling tbody tr:last-of-type td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.table-styling tbody tr td:first-of-type {
  border-left: none;
}

.table-styling tbody tr td:last-of-type {
  border-right: none;
}

.table-styling tbody tr:nth-of-type(even) td {
  background-color: #f5f7ff;
}

.table-styling tbody tr:nth-of-type(odd) td {
  background-color: #fff;
}

.table-styling tbody tr:hover td{
  background-color: #f0f0f0;
}

/* Dark Styling */

.table-styling-dark { 
  color: white;
}

.table-styling-dark th {
  background-color: #212529  !important;
}

.table-styling-dark tbody tr:nth-of-type(even) td {
  background-color: #2c3034  !important;
}

.table-styling-dark tbody tr:nth-of-type(odd) td {
  background-color: #212529 !important;
}

.table-styling-dark tbody tr:hover td{
  background-color: #3a4047 !important;
}