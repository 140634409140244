
.list-item-even,
.list-item-odd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-item-odd {
  background-color: #f5f7ff;
}

.list-item-even {
  background-color: #fff;
}

.loading-list-item {
  background-color: #e8f0fe;
}

.refresh-list-item {
  background-color: #e8f0fe;
}

.refresh-list-item-empty {
  background-color: #f6f6f6;
}

.sticky-header {
  position: sticky;
  z-index: 1;
  top: 0;
  background-color: #f1f3f4;
  font-weight: 500;
  padding: 15px 20px;
  border-bottom: #d9d9d9 1px solid;
  border-top: #d9d9d9 1px solid;
}