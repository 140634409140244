$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
  four-xl: 2000px,
  five-xl: 3000px,
  six-xl: 4000px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1340px,
  xxxl: 1540px,
  four-xl: 1965px,
  five-xl: 2940px,
  six-xl: 3940px
);

$primary: #001dd2;
$secondary: #545454;

$component-active-bg: $primary;

$nav-pills-link-active-bg: #F0F0F0;

$list-group-item-bg-scale: 0%;
$list-group-item-color-scale: 0%;

$btn-border-radius: 10px;
$btn-border-radius-sm: 10px;
$btn-border-radius-lg: 10px;
$btn-padding-y-sm: 7px;
$btn-padding-x-sm: 10px;

$card-border-width: 0px;
$card-border-radius: 6px;

$dropdown-border-color: rgb(221, 221, 221);
$dropdown-border-width: 2px;

$popover-header-bg: $primary;
$popover-header-color: #fff;

$offcanvas-horizontal-width: 450px;

// Breadcrumbs
$breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='currentColor' class='bi bi-chevron-right' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708'/%3E%3C/svg%3E");
$breadcrumb-border-radius: 6px; // Match border radius of cards
$breadcrumb-padding-x: 15px;
$breadcrumb-padding-y: 7px;
$breadcrumb-margin-bottom: 0;
$breadcrumb-bg: #fff;