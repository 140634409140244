// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here
@import "./variables.scss";
@import "./typography.scss";

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/root";

// 4. Include any optional Bootstrap components as you like
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/vendor/rfs";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/breadcrumb";

// Import more bootstrap components when required

//5. Include utilities API last to generate classes based on the Sass map
@import "~bootstrap/scss/utilities/api";

//6. Bootstrap utility class overrides (non-sass variables)

.card, .breadcrumb {
  box-shadow: 1px 1px 7px -4px #737373
}

.btn-outline-primary {
  --bs-btn-color: black;
  --bs-btn-bg: white;
}

.breadcrumb-item a {
  text-decoration: none;
}

.breadcrumb-item.active a {
  color: var(--bs-dark);
} 

// 6. Media queries on Bootstrap grid breakpoints
@import "./breakpoint-media-queries.scss";