.spinner {
	border: 0.25em solid currentcolor;
	border-right-color: rgba(0,0,0,0);
  line-height: 24px;
  box-sizing: border-box;
  display: inline-block;
	width: 1rem;
	height: 1rem;
  border-width: 0.2em;
	border-radius: 50%;
	animation: 0.75s linear infinite spinner-border;
  vertical-align: -0.125em;
}

.spinner-variant-dark {
	color: rgba(33, 37, 41);
}

.spinner-variant-light {
	color: rgba(248, 249, 250);
}

.visually-hidden {
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: -1px !important;
	overflow: hidden !important;
	white-space: nowrap !important;
	border: 0 !important;
}

@keyframes spinner-border {
  to { transform: rotate(360deg) }
}