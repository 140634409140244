@font-face {
  font-family: Inter;
  src: url(../fonts/InterVariable.woff2) format("woff2") tech("variations"),
       url(../fonts/InterVariable.woff2) format("woff2-variations");
  font-display: swap;
  font-weight: 100 900;
  font-style: normal;
}

$enable-rfs: false;
$p-font-size: 1rem;
$h6-font-size: 1.15rem;
$h5-font-size: 1.32rem;
$h4-font-size: 1.52rem;
$h3-font-size: 1.75rem;
$h2-font-size: 2rem;
$h1-font-size: 2.2rem;
$font-family-sans-serif: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

/* We add a body selector to each of the following rules to override the default Bootstrap styles */
/* Bootstrap does not give us a way to define font sizes for different breakpoints */

body .small {
  font-size: 0.875rem;
}

body .very-small {
  font-size: 0.75rem;
}

body .tiny {
  font-size: 0.625rem;
}

body .annotation {
  font-size: 0.5rem;
}

@media screen and (min-width: 3000px) {
  body h1 {
    font-size: 3.2rem;
  }

  body h2 {
    font-size: 2.8rem;
  }

  body h3 {
    font-size: 2.44rem;
  }

  body h4 {
    font-size: 1.95rem;
  }

  body h5 {
    font-size: 1.56rem;
  }

  body h6 {
    font-size: 1.25rem;
  }

  body p {
    font-size: 1rem;
  }

  body .small {
    font-size: 0.8rem;
  }

  body .very-small {
    font-size: 0.7rem;
  }

  body .tiny {
    font-size: 0.6rem;
  }

  body .annotation {
    font-size: 0.5rem;
  }
  
}

@media screen and (max-width: 576px) {
  body h1 {
    font-size: 2rem;
  }

  body h2 {
    font-size: 1.8rem;
  }

  body h3 {
    font-size: 1.6rem;
  }

  body h4 {
    font-size: 1.42rem;
  }

  body h5 {
    font-size: 1.26rem;
  }

  body h6 {
    font-size: 1.125rem;
  }

  body p {
    font-size: 1rem;
  }

  body .small {
    font-size: 0.89rem;
  }

  body .very-small {
    font-size: 0.79rem;
  }

  body .tiny {
    font-size: 0.70rem;
  }

  body .annotation {
    font-size: 0.62rem;
  }
}