/* Skeleton loading */
@keyframes skeletonShimmer {
  0% {
    background-position: -450px 0;
  }
  
  100% {
    background-position: 450px 0; 
  }
}

.skeleton-shine {
  background: #f4f5f6;
  background-image: linear-gradient(to right, #f4f5f6 0%, #edeef1 20%, #f4f5f6 50%, #f4f5f6 100%);
  background-repeat: no-repeat;
  background-size: 500px 500px; 
  position: relative; 

  animation-duration: 1.1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: skeletonShimmer;
  animation-timing-function: linear;
}