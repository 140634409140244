.alert {
	--alert-bg: transparent;
	--alert-color: inherit;
  --alert-border: 1px solid transparent;
	position: relative;
	padding: 1rem 1rem;
	margin-bottom: 1rem;
	color: var(--alert-color);
	background-color: var(--alert-bg);
	border: var(--alert-border);
	border-radius: 4px
}

.alert-info {
  --alert-color: #000f6e;
  --alert-bg: #ecf0ff;
  --alert-border: 1px solid #001dd2;
}

.alert-danger {
  --alert-color: #58151c;
  --alert-bg: #fdebeb;
  --alert-border: 1px solid #ff9b9b;
}

.alert-warning {
  --alert-color: #664d03;
  --alert-bg: #fff3cd;
  --alert-border: 1px solid #ffd336;
}

.alert-success {
  --alert-color: #0a3622;
  --alert-bg: #ebf9eb;
  --alert-border: 1px solid #3cc13b;
}

.alert-dismissable {
  transition: opacity 0.15s linear;
}

.alert-fade-out {
  animation: fadeOut 0.3s forwards;
}

.alert-btn-close {
  display: flex;
  align-items: center;
	color: #000;
	background: rgba(0,0,0,0) center/1em auto no-repeat;
	border: 0;
	border-radius: .375rem;
	opacity: 0.5;
}

.alert-btn-close:hover {
	text-decoration: none;
	opacity: 0.75;
}

.alert-btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 29, 210, 0.25);
}

.alert-dismissible .btn-close {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	padding: 1.25rem 1rem;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}