#Loading {
  height: 100vh;
}

.loading-container {
  height: 100%;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.loading-container-grid {
  display: grid;
  grid-template-columns: 80px auto;
  grid-template-rows: 1fr; 
  grid-template-areas: "loading-grid-sidebar loading-grid-icon";
}

.loading-grid-sidebar {
  grid-area: loading-grid-sidebar;
}

.loading-grid-icon {
  grid-area: loading-grid-icon;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-icon {
  z-index: 1;
  opacity: 0;
  animation: 
    initial-loading 0.1s ease-in,
    1.8s loading infinite ease-in-out 0.1s;
}

@supports ( height: 100svh ) {
  #Loading {
    height: 100svh;
  }
}

@keyframes initial-loading {
  0% {opacity: 0%;}
  50% {opacity: 10%}
  100% {opacity: 50%}
}

@keyframes loading {
  0% {opacity: 50%;}
  50% {opacity: 100%}
  100% {opacity: 50%;}
}