/* CSS Classes beginning with "responsive" will appear here */

.responsive-title-text {
  @include media-breakpoint-up(xl) {
    font-size: 2em
  }

  @include media-breakpoint-up(xxxl) {
    font-size: 2.4em
  }
}

.responsive-navbar-logo {
  @include media-breakpoint-up(md) {
    margin-bottom: 4px !important;
  }
}

.responsive-stripe-gauge {
  @include media-breakpoint-up(xs) {
    height: 35px;
    width: 100%;
    margin: 35px 0px;
  }

  @include media-breakpoint-up(lg) {
    height: 30px;
    width: 90%;
    margin: 30px 0px;
  }

  @include media-breakpoint-up(xl) {
    height: 35px;
    margin: 35px 0px;
  }

  @include media-breakpoint-up(xxxl) {
    height: 40px;
    margin: 40px 0px;
  }

  @include media-breakpoint-up(six-xl) {
    height: 55px;
    margin: 55px 0px;
  }
}

.responsive-stripe-gauge-card {
  @include media-breakpoint-up(xs) {
    height: 28px;
    width: 80%;
    margin: 13.75px 0px;
  }

  @include media-breakpoint-up(md) {
    height: 25px;
    margin: 10px 0px;
  }

  @include media-breakpoint-up(xxxl) {
    height: 30px;
    margin: 15px 0px;
  }
}

.responsive-single-gauge {
  @include media-breakpoint-up(xs) {
    height: 40px;
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    height: 30px;
    width: 70%;
  }

  @include media-breakpoint-up(xl) {
    height: 35px;
  }

  @include media-breakpoint-up(xxxl) {
    height: 40px;
  }

  @include media-breakpoint-up(six-xl) {
    height: 55px;
  }
}

.stripe-gauge-label-small {
  @include media-breakpoint-up(xs) {
    padding: 5px;
    font-weight: bold;
    display: initial;
    color: white;
    white-space: nowrap;
    font-size: 12px;
  }
  @include media-breakpoint-up(four-xl) {
    font-size: 18px;
  }
}

.responsive-border-form-column {
  @include media-breakpoint-up(xs) {
    border-bottom: 1px solid lightgrey;
    border-right: none;
  }

  @include media-breakpoint-up(md) {
    border-right: 1px solid lightgrey;
    border-bottom: none;
  }
}

.responsive-not-found-graphic {
  @include media-breakpoint-up(xs) {
    width: 300px;
  }

  @include media-breakpoint-up(md) {
    width: 600px;
  }

  @include media-breakpoint-up(lg) {
    width: 700px;
  }

  @include media-breakpoint-up(xl) {
    width: 1000px;
  }

  @include media-breakpoint-up(xxxl) {
    width: 1200px;
  }

  @include media-breakpoint-up(four-xl) {
    width: 1500px;
  }

  @include media-breakpoint-up(six-xl) {
    width: 2000px;
  }
}

.responsive-not-found-header {
  @include media-breakpoint-up(xs) {
    font-size: 2em;
  }

  @include media-breakpoint-up(xxxl) {
    font-size: 2.2em;
  }

  @include media-breakpoint-up(six-xl) {
    font-size: 2.5em;
  }
}

.responsive-not-found-text {
  @include media-breakpoint-up(xs) {
    font-size: 1em;
  }

  @include media-breakpoint-up(xxxl) {
    font-size: 1.2em;
  }

  @include media-breakpoint-up(six-xl) {
    font-size: 1.5em;
  }
}

.responsive-day-circle-container {
  @include media-breakpoint-up(xs) {
    justify-content: center;
  }

  @include media-breakpoint-up(sm) {
    justify-content: space-around;
  }

  @include media-breakpoint-up(lg) {
    justify-content: center;
  }

  @include media-breakpoint-up(xxxl) {
    justify-content: space-around;
  }
}

/* Responsive grids */
.graphic-stripe-container-grid {
  @include media-breakpoint-up(xs) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 270px 0.3fr 0.6fr;
    gap: 0px 0px;
    grid-template-areas:
    "grid-graphic"
    "grid-controls"
    "grid-stripe";
  }

  @include media-breakpoint-up(sm) {
    grid-template-rows: minmax(450px, 1fr) 0.3fr 0.6fr;
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: 0.7fr 1fr;
    grid-template-rows: minmax(250px, 1fr);
    gap: 0px 80px;
    grid-template-areas:
    "grid-graphic grid-stripe"
    "grid-controls .";
  }

  @include media-breakpoint-up(lg) {
    grid-template-rows: minmax(250px, 1fr);
  }

  @include media-breakpoint-up(xxl) {
    grid-template-rows: minmax(300px, 1fr);
  }

  @include media-breakpoint-up(five-xl) {
    grid-template-rows: minmax(450px, 1fr);
  }
}

@media (min-width: 420px) and (max-width: 576px) {
  .graphic-stripe-container-grid {
    grid-template-rows: 360px 0.3fr 0.6fr;
  }
}

.grid-stripe {
  @include media-breakpoint-up(xs) {
    grid-area: grid-stripe;
  }

  @include media-breakpoint-up(md) {
    align-self: center;
  }
}

.grid-graphic {
  @include media-breakpoint-up(xs) {
    grid-area: grid-graphic;
    width: 60%;
    align-self: center;
  }
  
  @include media-breakpoint-up(md) {
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    width: 90%;
  }

  @include media-breakpoint-up(xxxl) {
    width: 80%;
  }

  @include media-breakpoint-up(four-xl) {
    width: 60%;
  }
}

.grid-controls {
  grid-area: grid-controls;
}

.asset-monitoring-grid {
  @include media-breakpoint-up(xs) {
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: 1fr; 
    // transition: 500ms;
  }

  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: 80px auto;
    grid-template-rows: 1fr; 
  }
}

.asset-monitoring-grid-grow {
  @include media-breakpoint-up(xs) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto; 
    // transition: 500ms;
  }

  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-template-rows: 1fr; 
  }

  @include media-breakpoint-up(four-xl) {
    grid-template-columns: 300px 1fr;
  }

  @include media-breakpoint-up(five-xl) {
    grid-template-columns: 500px 1fr;
  }
}

/* Extending display, padding and margins to custom breakpoints */
.d-six-xl-none {
  @include media-breakpoint-up(xs) {
    display: block;
  }

  @include media-breakpoint-up(six-xl) {
    display: none;
  }
}

.pe-four-xl-3 {
  @include media-breakpoint-up(xs) {
    padding-right: 0px;
  }

  @include media-breakpoint-up(four-xl) {
    padding-right: 15px;
  }
}