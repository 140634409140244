.evos-btn-group {
	border-radius: 10px;
}

.evos-btn-group, .evos-btn-group-vertical {
	position: relative;
	display: inline-flex;
	vertical-align: middle;
}

.evos-btn-check {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none;
}

.evos-btn-check-content {
  display: flex;
  align-items: center;
  padding: 6px 0px;
  margin: 0;
}

.evos-btn-check-content.sm {
  padding: 4px 0px;
}

.evos-btn-check-content > * {
  margin: 0;
}

.evos-btn-check-content > *:not(:last-child) {
  margin-right: 5px;
}

.evos-btn-group > .evos-btn, .evos-btn-group-vertical > .evos-btn {
	position: relative;
	flex: 1 1 auto;
}

.evos-btn-check:checked + .evos-btn, :not(.evos-btn-check) + .evos-btn:active, .evos-btn:first-child:active, .evos-btn.active, .evos-btn.show {
	color: var(--btn-active-color);
	background-color: var(--btn-active-bg);
	border-color: var(--btn-active-border-color);
}

.evos-btn-group > .evos-btn-check:checked + .evos-btn, .evos-btn-group > .evos-btn-check:focus + .evos-btn, .evos-btn-group > .evos-btn:hover, .evos-btn-group > .evos-btn:focus, .evos-btn-group > .evos-btn:active, .evos-btn-group > .evos-btn.active, .evos-btn-group-vertical > .evos-btn-check:checked + .evos-btn, .evos-btn-group-vertical > .evos-btn-check:focus + .evos-btn, .evos-btn-group-vertical > .evos-btn:hover, .evos-btn-group-vertical > .evos-btn:focus, .evos-btn-group-vertical > .evos-btn:active, .evos-btn-group-vertical > .evos-btn.active {
	z-index: 1;
}

.evos-btn-group > .evos-btn:not(:last-child):not(.dropdown-toggle), .evos-btn-group > .evos-btn.dropdown-toggle-split:first-child, .evos-btn-group > .evos-btn-group:not(:last-child) > .evos-btn {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.evos-btn-group > .evos-btn:nth-child(n+3), .evos-btn-group > :not(.evos-btn-check) + .evos-btn, .evos-btn-group > .evos-btn-group:not(:first-child) > .evos-btn {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.evos-btn-group > :not(.evos-btn-check:first-child) + .evos-btn, .evos-btn-group > .evos-btn-group:not(:first-child) {
	margin-left: calc(var(--border-width) * -1);
}