.evos-btn {
	--btn-padding-x: 0.75rem;
	--btn-padding-y: 0.375rem;
	--btn-font-size: 1rem;
	--btn-font-weight: 400;
	--btn-line-height: 1.5;
	--btn-border-width: 1px;
	--btn-border-radius: 10px;
	display: inline-block;
	padding: var(--btn-padding-y) var(--btn-padding-x);
	font-size: var(--btn-font-size);
	font-weight: var(--btn-font-weight);
	line-height: var(--btn-line-height);
	color: var(--btn-color);
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	-webkit-user-select: none;
	user-select: none;
	border: var(--btn-border-width) solid var(--btn-border-color);
	border-radius: var(--btn-border-radius);
	background-color: var(--btn-bg);
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.evos-btn-primary {
	--btn-color: #fff;
	--btn-bg: #001dd2;
	--btn-border-color: #001dd2;
	--btn-hover-color: #fff;
	--btn-hover-bg: #0019b3;
	--btn-hover-border-color: #0017a8;
	--btn-focus-shadow-rgb: 38, 63, 217;
	--btn-active-color: #fff;
	--btn-active-bg: #0017a8;
	--btn-active-border-color: #00169e;
	--btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--btn-disabled-color: #fff;
	--btn-disabled-bg: #001dd2;
	--btn-disabled-border-color: #001dd2;
}

.evos-btn-secondary {
	--btn-color: #fff;
	--btn-bg: #545454;
	--btn-border-color: #545454;
	--btn-hover-color: #fff;
	--btn-hover-bg: #474747;
	--btn-hover-border-color: #434343;
	--btn-focus-shadow-rgb: 110, 110, 110;
	--btn-active-color: #fff;
	--btn-active-bg: #434343;
	--btn-active-border-color: #3f3f3f;
	--btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--btn-disabled-color: #fff;
	--btn-disabled-bg: #545454;
	--btn-disabled-border-color: #545454;
}

.evos-btn-success {
	--btn-color: #fff;
	--btn-bg: #198754;
	--btn-border-color: #198754;
	--btn-hover-color: #fff;
	--btn-hover-bg: #157347;
	--btn-hover-border-color: #146c43;
	--btn-focus-shadow-rgb: 60, 153, 110;
	--btn-active-color: #fff;
	--btn-active-bg: #146c43;
	--btn-active-border-color: #13653f;
	--btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--btn-disabled-color: #fff;
	--btn-disabled-bg: #198754;
	--btn-disabled-border-color: #198754;
}

.evos-btn-danger {
	--btn-color: #fff;
	--btn-bg: #dc3545;
	--btn-border-color: #dc3545;
	--btn-hover-color: #fff;
	--btn-hover-bg: #bb2d3b;
	--btn-hover-border-color: #b02a37;
	--btn-focus-shadow-rgb: 225, 83, 97;
	--btn-active-color: #fff;
	--btn-active-bg: #b02a37;
	--btn-active-border-color: #a52834;
	--btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--btn-disabled-color: #fff;
	--btn-disabled-bg: #dc3545;
	--btn-disabled-border-color: #dc3545;
}

.evos-btn-warning {
	--btn-color: #000;
	--btn-bg: #ffc107;
	--btn-border-color: #ffc107;
	--btn-hover-color: #000;
	--btn-hover-bg: #ffca2c;
	--btn-hover-border-color: #ffc720;
	--btn-focus-shadow-rgb: 217, 164, 6;
	--btn-active-color: #000;
	--btn-active-bg: #ffcd39;
	--btn-active-border-color: #ffc720;
	--btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--btn-disabled-color: #000;
	--btn-disabled-bg: #ffc107;
	--btn-disabled-border-color: #ffc107;
}

.evos-btn-link {
	--btn-font-weight: 400;
	--btn-color: var(--link-color);
	--btn-bg: transparent;
	--btn-border-color: transparent;
	--btn-hover-color: var(--link-hover-color);
	--btn-hover-border-color: transparent;
	--btn-active-color: var(--link-hover-color);
	--btn-active-border-color: transparent;
	--btn-disabled-color: #6c757d;
	--btn-disabled-border-color: transparent;
	--btn-focus-shadow-rgb: 38, 63, 217;
	text-decoration: underline;
}

.evos-btn-outline-primary {
	--btn-color: #001dd2;
	--btn-border-color: #001dd2;
	--btn-hover-color: #fff;
	--btn-hover-bg: #001dd2;
	--btn-hover-border-color: #001dd2;
	--btn-focus-shadow-rgb: 0, 29, 210;
	--btn-active-color: #fff;
	--btn-active-bg: #001dd2;
	--btn-active-border-color: #001dd2;
	--btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--btn-disabled-color: #001dd2;
	--btn-disabled-bg: transparent;
	--btn-disabled-border-color: #001dd2;
}

.evos-btn-outline-secondary {
	--btn-color: #545454;
	--btn-border-color: #545454;
	--btn-hover-color: #fff;
	--btn-hover-bg: #545454;
	--btn-hover-border-color: #545454;
	--btn-focus-shadow-rgb: 84, 84, 84;
	--btn-active-color: #fff;
	--btn-active-bg: #545454;
	--btn-active-border-color: #545454;
	--btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--btn-disabled-color: #545454;
	--btn-disabled-bg: transparent;
	--btn-disabled-border-color: #545454;
}

.evos-btn-outline-success {
	--btn-color: #198754;
	--btn-border-color: #198754;
	--btn-hover-color: #fff;
	--btn-hover-bg: #198754;
	--btn-hover-border-color: #198754;
	--btn-focus-shadow-rgb: 25, 135, 84;
	--btn-active-color: #fff;
	--btn-active-bg: #198754;
	--btn-active-border-color: #198754;
	--btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--btn-disabled-color: #198754;
	--btn-disabled-bg: transparent;
	--btn-disabled-border-color: #198754;
}

.evos-btn-outline-warning {
	--btn-color: #ffc107;
	--btn-border-color: #ffc107;
	--btn-hover-color: #000;
	--btn-hover-bg: #ffc107;
	--btn-hover-border-color: #ffc107;
	--btn-focus-shadow-rgb: 255, 193, 7;
	--btn-active-color: #000;
	--btn-active-bg: #ffc107;
	--btn-active-border-color: #ffc107;
	--btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--btn-disabled-color: #ffc107;
	--btn-disabled-bg: transparent;
	--btn-disabled-border-color: #ffc107;
}

.evos-btn-outline-danger {
	--btn-color: #dc3545;
	--btn-border-color: #dc3545;
	--btn-hover-color: #fff;
	--btn-hover-bg: #dc3545;
	--btn-hover-border-color: #dc3545;
	--btn-focus-shadow-rgb: 220, 53, 69;
	--btn-active-color: #fff;
	--btn-active-bg: #dc3545;
	--btn-active-border-color: #dc3545;
	--btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--btn-disabled-color: #dc3545;
	--btn-disabled-bg: transparent;
	--btn-disabled-border-color: #dc3545;
}

.evos-btn:disabled, .evos-btn.disabled {
	color: var(--btn-disabled-color);
	pointer-events: none;
	background-color: var(--btn-disabled-bg);
	border-color: var(--btn-disabled-border-color);
	opacity: 0.65;
}

.evos-btn:hover {
	color: var(--btn-hover-color);
	background-color: var(--btn-hover-bg);
	border-color: var(--btn-hover-border-color);
}

.evos-btn-check:checked + .evos-btn, :not(.evos-btn-check) + .evos-btn:active, .evos-btn:first-child:active, .evos-btn.active, .evos-btn.show {
	color: var(--btn-active-color);
	background-color: var(--btn-active-bg);
	border-color: var(--btn-active-border-color);
}

.evos-btn:focus-visible {
	color: var(--btn-hover-color);
	background-color: var(--btn-hover-bg);
	border-color: var(--btn-hover-border-color);
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(var(--btn-focus-shadow-rgb), .5);
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
	cursor: pointer;
}

.evos-btn span {
  margin-left: 0.25rem;
}